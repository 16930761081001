.app {
    height: 100%;
}

.content {
    display: grid;
    grid-template-areas:
        "header"
        "main";
    grid-template-rows: min-content auto;
    height: 100%;
}

.header {
    grid-area: header;
    border-bottom: 1px solid #bbb;
}

.main {
    grid-area: main;
    overflow: auto;
    width: 100%;
}
