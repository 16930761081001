html, body {
	height: 100%;
	width: 100%;
	padding: 0;
	margin: 0;
	background: #FAFAFA;
	font-family: Roboto, arial, sans-serif;
	font-weight: 400;
	color: #444;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

    --mdc-theme-primary: black !important;
    --mdc-theme-secondary: black !important;
}

#app {
	height: 100%;
}
